import app from "gatsby-plugin-firebase-v9.0";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { DB_COLLECTION_USERS } from "../../constants/database";
import {
  cloudFunctionAddMailchimpTags,
  cloudFunctionGeoLocation,
} from "../endpoints";

const database = getFirestore(app);

export const createUser = async (payload) => {
  await setDoc(doc(database, DB_COLLECTION_USERS, payload.uid), {
    id: payload.uid,
    email: payload.email,
    fullName: payload.displayName,
    firstName: null,
    lastName: null,
    ghUsername: payload.reloadUserInfo.providerUserInfo[0].screenName || null,
    avatar: payload.photoURL || null,
    countryCode: payload.countryCode || null,
    currency: payload.currency || null,
    products: [],
    level: [],
    technologies: [],
    metaData: {
      registerDate: payload.metadata.creationTime,
      lastSignInDate: payload.metadata.lastSignInTime,
    },
  });
};

export const getUserRegion = async () => {
  try {
    const response = await fetch(cloudFunctionGeoLocation);
    if (!response.ok) {
      throw new Error("getUserRegion network response was not ok.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(
      "LOCATION -- There was an error fetching the location data:",
      error,
    );
  }
};

// NOT IN USE: Use if we need to update previous records with this information at a later date

// export const updateUserCountry = async (uid, payload) => {
//   console.log("updateUserCountry PAYLOAD", payload);
//   await updateDoc(doc(database, DB_COLLECTION_USERS, uid), {
//     countryCode: payload.countryCode,
//     currency: payload.currency,
//   });
// };

export const updateUserActivity = async (uid, payload) => {
  await updateDoc(doc(database, DB_COLLECTION_USERS, uid), {
    "metaData.lastSignInDate": payload.metadata.lastSignInTime,
  });
};

export const updateUserProducts = async (uid, payload) => {
  // Use this only when purchase is free and doesn't use Stripe.
  // All Stripe purchases handle this via a Firebase Cloud Function
  // (under functions/payments, see webhook 'checkout.session.completed')
  // So it's unnecessary to use this method in those cases.

  const hasPurchased = await doesUserHaveProduct(uid, payload.projectId);
  if (!hasPurchased) {
    // add it to user in Firestore.
    const newPayment = {
      customerId: uid,
      customerEmail: payload.email,
      productId: payload.projectId,
      paymentIntentId: payload.paymentIntentId,
      amount: payload.amount,
      currency: payload.currency,
      purchaseDate: new Date(payload.created),
      country: payload.country,
    };

    await updateDoc(doc(database, DB_COLLECTION_USERS, uid), {
      products: arrayUnion(newPayment),
    });
    // ADD project tag to user in Mailchimp
    const tags = [
      { name: payload.projectId, status: "active" },
      { name: "project-enrolled", status: "active" },
    ];
    await fetch(cloudFunctionAddMailchimpTags, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: payload.email, tags }),
    });
  }
};

export const doesUserHaveProduct = async (uid, productId) => {
  const userDoc = await getDoc(doc(database, DB_COLLECTION_USERS, uid));

  if (!userDoc.exists) {
    return false;
  }
  const userProducts = userDoc.data().products || [];
  const hasPurchased = userProducts.some(
    (product) => product.productId === productId,
  );

  return hasPurchased;
};

export const doesUserExist = async (uid) => {
  const usersCollection = collection(database, DB_COLLECTION_USERS);
  const userDocRef = doc(usersCollection, uid);
  const userDocSnapshot = await getDoc(userDocRef);

  return userDocSnapshot.exists();
};

export const getUser = async (uid) => {
  const userData = await getDoc(doc(database, DB_COLLECTION_USERS, uid));
  if (userData.exists()) {
    return userData.data();
  }
};

export const deleteUser = (uid) => {};
