import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getUserRegion } from "../api/users/userApi";

export const MetaContext = createContext();

export const MetaProvider = ({ children }) => {
  const [regionAndCurrency, setRegionAndCurrency] = useState({
    countryCode: null,
    currency: null,
  });

  useEffect(() => {
    let isMounted = true;

    // Only fetch the region data if it's not already set
    if (!regionAndCurrency.countryCode) {
      async function getRegion() {
        try {
          const regionAndCurrencyResponse = await getUserRegion();
          if (isMounted && regionAndCurrencyResponse)
            setRegionAndCurrency(regionAndCurrencyResponse);
        } catch (error) {
          console.error("Error fetching user region:", error);
        }
      }
      getRegion();
    }

    return () => {
      isMounted = false;
    };
  }, [regionAndCurrency]);

  return (
    <MetaContext.Provider
      value={{
        countryCode: regionAndCurrency.countryCode,
        currency: regionAndCurrency.currency,
      }}
    >
      {children}
    </MetaContext.Provider>
  );
};

MetaProvider.propTypes = {
  children: PropTypes.node,
};
