exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-github-js": () => import("./../../../src/pages/auth/github.js" /* webpackChunkName: "component---src-pages-auth-github-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-prismic-project-data-slug-js": () => import("./../../../src/pages/projects/{PrismicProject.data__slug}.js" /* webpackChunkName: "component---src-pages-projects-prismic-project-data-slug-js" */),
  "component---src-pages-purchase-complete-prismic-project-uid-js": () => import("./../../../src/pages/purchase-complete/{PrismicProject.uid}.js" /* webpackChunkName: "component---src-pages-purchase-complete-prismic-project-uid-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-sign-up-thank-you-js": () => import("./../../../src/pages/sign-up/thank-you.js" /* webpackChunkName: "component---src-pages-sign-up-thank-you-js" */),
  "component---src-pages-terms-and-privacy-js": () => import("./../../../src/pages/terms-and-privacy.js" /* webpackChunkName: "component---src-pages-terms-and-privacy-js" */)
}

