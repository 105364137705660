import { navigate } from "gatsby";
import {
  GithubAuthProvider,
  getAuth,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import {
  doesUserExist,
  createUser,
  updateUserActivity,
  getUser,
} from "../../api/users/userApi";

const provider = new GithubAuthProvider();
const auth = getAuth();

export const login = async (customRedirect) => {
  if (customRedirect) {
    // Get the current URL parameters, or create a new URLSearchParams object if there are none.
    const urlParams = new URLSearchParams(window.location.search);

    // Set the redirectTo parameter to your custom link.
    urlParams.set("redirectTo", customRedirect);

    // Update the URL without causing a page reload.
    navigate(window.location.pathname + "?" + urlParams.toString(), {
      replace: true,
    });
  }

  await signInWithRedirect(auth, provider);
};

export const logout = () => {
  signOut(auth)
    .then(() => {
      localStorage.clear();
    })
    .catch((error) => {
      console.log("Sign out error", error);
    });
};

export const getUserFromLogin = async (authUser, userMeta) => {
  const { uid: userId } = authUser;
  // take github auth ID and check if we know this user
  // if unknown user, create one in DB
  // if known user, update it with new activity data
  const newUser = !(await doesUserExist(userId));
  if (newUser) {
    const combinedPayload = { ...authUser, ...userMeta };
    await createUser(combinedPayload);
  } else {
    await updateUserActivity(userId, authUser);
    // NOT IN USE: Use if we need to update previous records with this information at a later date
    // if (userMeta.country) {
    //   await updateUserCountry(userId, userMeta);
    // }
  }
  // Get user from Firestore
  const dbUser = await getUser(userId);

  return dbUser;
};
