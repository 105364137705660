import * as React from "react";
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { AuthProvider } from "./src/context/authContext";
import { MetaProvider } from "./src/context/metaContext";
import { ModalProvider } from "./src/context/modalContext";

import "./src/styles/global.css";

// gatsby-browser.js

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Example: Log pageview with Firebase Analytics
  const analytics = getAnalytics();
  logEvent(analytics, "page_view", {
    page_path: location.pathname,
  });
};

export const wrapRootElement = ({ element }) => (
  <MetaProvider>
    <AuthProvider>
      <ModalProvider>
        <PrismicProvider
          internalLinkComponent={({ href, ...props }) => (
            <Link to={href} {...props} />
          )}
        >
          {element}
        </PrismicProvider>
      </ModalProvider>
    </AuthProvider>
  </MetaProvider>
);
