module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CODECARAVAN","short_name":"CODECARAVAN","start_url":"/","background_color":"#ffffff","theme_color":"#F5F1E9","display":"standalone","icon":"/Users/lulumac/SPIDEROAK/Roycroft-Labs/Code Caravan/Website/platform/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5875f3ca24e2c72e7b65fdd722e0fae6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
