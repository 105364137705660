import React, { createContext, useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";

import app from "gatsby-plugin-firebase-v9.0";

import { getAuth } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { getUserFromLogin } from "../utils/auth";
import { MetaContext } from "../context/metaContext";
import { getUser } from "../api/users/userApi";

const auth = getAuth(app);

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userState, setUser] = useState(null); // full user data with firestore ID
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authUser, loading, error] = useAuthState(auth); // github only
  const userMeta = useContext(MetaContext);

  useEffect(() => {
    // useAuthState uses onAuthStateChanged under the hood
    if (authUser) {
      setIsLoggedIn(true);
      // Setting isLoggedIn here when we receive authUser, and not after we get the dbUser back from Firestore, gives faster feedback to the user that they are logged in, as there is a delay before we get the Firestore user. This does mean however that there is a delay between appearing as logged in, and seeing any user data displayed, as we only use db user data throughout the platform.
      // (we get the user object from github first - that's authUser - then we go and create/update/fetch it in Firestore before we save it in Context - that's userState - which is why there is a delay beween the two).

      (async () => {
        const dbUser = await getUserFromLogin(authUser, userMeta);
        // and save it locally in Context
        setUser(dbUser);
      })();
    } else {
      setUser(null);
      setIsLoggedIn(false);
    }
  }, [authUser, userMeta]);

  useEffect(() => {
    //  handle redirects after sign-in
    //  e.g. user clicks to buy product, signs in on 3rd party,
    //  comes back to us and we redirect them as needed.
    const urlParams = new URLSearchParams(window.location.search);
    const redirectDestination = urlParams.get("redirectTo");

    if (redirectDestination && userState) {
      console.log("userState", userState);
      // User just signed in and URL has `redirectTo` parameter.
      // Now you can redirect to the specified page.
      if (redirectDestination.includes("stripe")) {
        console.log("userState id", userState.id);

        navigate(
          `${redirectDestination}?prefilled_email=${userState.email}&client_reference_id=${userState.id}`,
        );
      } else if (redirectDestination.includes("sendowl")) {
        navigate(`${redirectDestination}?buyer_email=${userState.email}`);
      } else {
        navigate(redirectDestination);
      }
    }
  }, [userState]);

  const updateUser = async () => {
    if (authUser) {
      const updatedUser = await getUser(authUser.uid);
      setUser(updatedUser);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: userState,
        loading: loading,
        error: error,
        isLoggedIn: isLoggedIn,
        updateUser: updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
