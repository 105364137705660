import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [openModalKey, setOpenModalKey] = useState(null);

  const openModal = (modalKey) => {
    setOpenModalKey(modalKey);
  };

  const closeModal = () => {
    setOpenModalKey(null);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        openModalKey,
        setOpenModalKey,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};
